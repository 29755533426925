;$(function() {
    // Context change

    // Redirections:
    const fiStudents = '/';
    const fiCompanies = '/yritysvalmennus';
    const seStudents = '/sv/';
    const seCompanies = '/sv/foretagsutbildning';

    // change context when user clicks on the context switcher, which are id="students" and id="companies" under id="context-switcher" in header
    $('#context-switcher, #context-switcher-mobile').on('click', 'a', function(e) {
        e.preventDefault();
        
        // Context from clicked link
        let context = $(this).attr('id');
        
        // Set/renew the context to cookies for 30 days
        Cookies.set('context', context, { expires: 30 });

        // Context from page (default is "students")
        let pageContext = $('body').attr('data-context') || 'students';

        
        // if the context is same as current page context
        if (context === pageContext) {
            // return; // go to context front page
        }

        // HTML lang attribute
        let lang = $('html').attr('lang');

        // Redirect to the front page of the context with the same language
        if (lang === 'sv') {
            if (context === 'students') {
                window.location.href = seStudents;
            } else if (context === 'companies') {
                window.location.href = seCompanies;
            }
        } else {
            if (context === 'students') {
                window.location.href = fiStudents;
            } else if (context === 'companies') {
                window.location.href = fiCompanies;
            }
        }
    });

    // when clicking logo link, use context for redirection
    $('.logo-container').on('click', 'a', function(e) {
        let lang = $('html').attr('lang');
        let context = $('body').attr('data-context') || 'students';
        if (context === 'students') {
            return;
        }
        if (context === 'companies') {
            e.preventDefault();
            if (lang === 'sv') {
                window.location.href = seCompanies;
            } else {
                window.location.href = fiCompanies;
            }
        }
        // if no page context, check the cookie
        let cookie = Cookies.get('context');
        if (cookie === 'companies') {
            e.preventDefault();
            if (lang === 'sv') {
                window.location.href = seCompanies;
            } else {
                window.location.href = fiCompanies;
            }
        }
    });
});