(function($){
    $(function() {
        $('.gallery-item').magnificPopup({
            gallery: {
                enabled: true
            },
            type:'image',
            image: {
                titleSrc: 'title'
            }
        });
    });
})($);