;$(function() {

	var wasEventTriggeredByKeyboard = function(e) {
		return (
		    (e.pageX === 0 && e.pageY === 0) // chrome
		 || (e.timeStamp === 0)              // firefox
		);
	};

	$('li', '#main-navigation').has('ul').each(function() {
		$(this).find('>a').wrap('<span class="wrap-for-expand"></span>');
		$(this).find('>span').append(
			$('<a/>', {
				'class' : 'expand', //+ (($(this).hasClass('nav-path-selected')) ? 'close' : '')
				'href'  : '#',
				'html'  : '<span class="icon">',
				'aria-expanded': 'false',
				'role'  : 'button',
				'aria-label': 'Expand menu item'
			}).on('click.expandable-mega-menu', function(e) {
				e.preventDefault();
				const submenu = $(this).toggleClass('close').parent('span').siblings('ul');
				// submenu.slideToggle();
				// imitate slidetoggle, but with flex instead of block
				if (submenu.is(':visible')) {
					submenu.slideUp();
				} else {
					submenu.slideDown({
						start: function () {
						  $(this).css({
							display: "flex"
						  })
						}
					});
					
					// Make sure submenu does not go over left side of the screen
					var left = submenu.offset().left;
					if (left < 0) {
						submenu.css('left', 0);
						submenu.css('right', 'auto');
					}
				}

				// close other menus, but not the parent
				$(this).closest('li').siblings('li').find('.expand.close').removeClass('close').parent('span').siblings('ul').slideUp();
				// close child menus
				if ( ! $(this).hasClass('close')) {
					$(this).parent('span').siblings('ul').find('.expand.close').removeClass('close').parent('span').siblings('ul').slideUp();
					$(this).parent('span').siblings('ul').find('.expand').attr('aria-expanded', false);
				}
				if (wasEventTriggeredByKeyboard(e) === false) {
					// blur so we wont get stuck on focus style
					$(this).trigger('blur');
				}
				// set aria-expanded attribute
				$(this).attr('aria-expanded', $(this).hasClass('close'));
				// set aria-expanded attribute for other menus and their children
				$(this).closest('li').siblings('li').find('.expand').attr('aria-expanded', false);
				
			})
		);
	});

	$('.toggle-navigation[data-target="#main-navigation"]').on('click.expandable-mega-menu', function(e) {
		// close all menus when hiding navigation
		if ( ! $(this).hasClass('collapsed')) {
			// trigger toggle on opened first level menus
			//$('#main-navigation ul:first-child > li > span.wrap-for-expand a.expand.close').trigger('click');

			// reset menu state to original
			$('#main-navigation ul > li ul').removeAttr('style');
			$('#main-navigation ul > li a.expand.close').removeClass('close');
			$('#main-navigation ul > li ul:visible').prev('.wrap-for-expand').find('a.expand').addClass('close');
			// set aria-expanded attribute
			$('#main-navigation ul > li a.expand').attr('aria-expanded', false);
		}
		if (wasEventTriggeredByKeyboard(e) === false) {
			// blur so we wont get stuck on focus style
			$(this).trigger('blur');
		}
	});

	$('.toggle-languages[data-target="#languages"]').on('click.expandable-mega-menu', function(e) {
		if (wasEventTriggeredByKeyboard(e) === false) {
			// blur so we wont get stuck on focus style
			$(this).trigger('blur');
		}
	});

});