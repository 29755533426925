$(function(){
	"use strict";

	/* global google */
	/* global MarkerClusterer */
	/* global InfoBubble */

	var pluginName = 'pagesOnMap';
	var dataKey = pluginName;

	var methods = {
		init: function(inputOptions) {
			var defaultOptions = {
				enableClusterer: true,

				googleMaps: {
					zoom             : 5,
					center           : new google.maps.LatLng(62.242009, 25.795898),
					mapTypeId        : google.maps.MapTypeId.ROADMAP,
				},

				infoBubble: {
					borderRadius: 10,
					borderWidth : 4,
					borderColor : '#8c1911'
				},

				markerClustererStyles: {
					height   : 72, // 55 + 17 (anchor)
					width    : 56,
					anchor   : [17, 0],
					textColor: '#fff',
					textSize : 14
				},

				items: [], // expect keys:
				markers: [],
				markerClusterer: null,
				infoBubbles: []
			};
			return this
				.filter(function(){
					return $(this).data(dataKey) === undefined;
				})
				.each(function(){
					var $this = $(this);
					// clone options to this specific element
					var options = $.extend(true, defaultOptions, inputOptions);

					// init
					var map = options.map = new google.maps.Map(this, $.extend(true, defaultOptions.googleMaps, inputOptions.googleMaps));

					$.each(options.items, function(i, item) {
						if ( ! item.position) {
							item.position = new google.maps.LatLng(item.lat, item.lng);
						}

						var marker = item.marker = new google.maps.Marker({
							position : item.position,
							icon     : item.icon || options.icon,
							map      : options.map,
							title    : item.title
						});
						options.markers.push(item.marker);

						var infoBubble = item.infoBubble = new InfoBubble(
							$.extend({}, {content: item.info}, defaultOptions.infoBubble, inputOptions.infoBubble)
						);

						options.infoBubbles.push(item.infoBubble);

						google.maps.event.addListener(item.marker, 'click', function() {
							$this[pluginName]('closeInfoBubbles');
							infoBubble.open(map, marker);
						});
					});

					google.maps.event.addListener(map, 'drag', function() {
						$this[pluginName]('closeInfoBubbles');
						$this.trigger('map-change', { target: $this });
					});
					google.maps.event.addListener(map, 'click', function() {
						$this[pluginName]('closeInfoBubbles');
						$this.trigger('map-change', { target: $this });
					});

					if (options.enableClusterer) {
						options.markerClusterer = new MarkerClusterer(
							map,
							options.markers,
							{styles: [options.markerClustererStyles]}
						);
					}

					$this.data(dataKey, options);
				});
		},

		zoom: function(level) {
			this.data(pluginName).map.setZoom(level);
			return this;
		},

		center: function(latLng, animate) {
			this.data(pluginName).map[animate ? 'panTo' : 'setCenter'](latLng);
			return this;
		},

		centerToItem: function(itemOrIndex, animate) {
			var data = this.data(pluginName);
			var item = (typeof itemOrIndex === 'object') ? itemOrIndex : data.items[itemOrIndex];
			if (item) {
				methods.center.call(this, item.position, animate);
			}
			return this;
		},

		/* @return google.maps.LatLng */
		getItemsCenter: function(items) {
			console.log('got items', items);
			if (items.length > 0) {
				var latLngBounds = new google.maps.LatLngBounds();
				$.each(items, function(i, item) {
					latLngBounds.extend(item.position);
					console.log('adding pos from ', item, 'to', latLngBounds);
				});
				console.log('center is ', latLngBounds.getCenter());
				return latLngBounds.getCenter();
			}
			return undefined;
		},

		filterItems: function(iterator) {
			var data = this.data(pluginName);
			// use native if present
			if (Array.prototype.filter && data.items.filter === Array.prototype.filter) {
				return data.items.filter(iterator);
			}
			var res = [];
			$.each(data.items, function(i, item){
				if (iterator(item, i, data.items)) {
					res.push(item);
				}
			});
			return res;
		},

		getClosest: function(latLng, animate) {
			var data = this.data(pluginName);

			// find closest
			// http://stackoverflow.com/questions/4057665/google-maps-api-v3-find-nearest-markers

			var rad = function(x) {
				return x * Math.PI / 180;
			};

			var targetLat = latLng.lat();
			var targetLng = latLng.lng();
			var R = 6371; // radius of earth in km
			var closest = false;
			var closestDistance = Infinity;

			$.each(data.items, function(i, item) {
				var mLat = item.marker.position.lat();
				var mLng = item.marker.position.lng();
				var dLat = rad(mLat - targetLat);
				var dLng = rad(mLng - targetLng);
				var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
					Math.cos(rad(targetLat)) * Math.cos(rad(targetLat)) *
					Math.sin(dLng / 2) * Math.sin(dLng / 2);
				var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
				var distance = R * c;
				if (closest === false || distance < closestDistance) {
					closest = item;
					closestDistance = distance;
				}
			});
			return closest;
		},

		showAllMarkers: function() {
			$.each(this.data(pluginName).items, function (i, item) {
				item.marker.setVisible(true);
			});
			return this;
		},

		showMarkersByGroup: function(group) {
			$.each(this.data(pluginName).items, function (i, item) {
				item.marker.setVisible(item.groups && $.inArray(group, item.groups));
			});
			return this;
		},

		showInfoBubble: function(itemOrIndex) {
			var data = this.data(pluginName);
			var item = (typeof itemOrIndex === 'object') ? itemOrIndex : data.items[itemOrIndex];
			if (item) {
				methods.closeInfoBubbles.call(this);
				item.infoBubble.open(data.map, item.marker);
			}
			return this;
		},

		closeInfoBubbles: function() {
			var data = this.data(pluginName);
			//$.each(data.infoBubbles, function(i, infoBubble) {
			//	infoBubble.close(data.map);
			//});
			return this;
		},

		destroy: function(){
			return $(this)
				.removeData(dataKey)
				.off('.' + pluginName) // unbind our namespace
				.end();
		}
	};

	jQuery.fn[pluginName] = function(method) {
		if (methods[method]) {
			return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
		} else if (typeof method === 'object' || ! method) {
			return methods.init.apply(this, arguments);
		}
		return $.error('Method ' + method + ' does not exist on ' + pluginName);
	};
});
