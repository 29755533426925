;$(function() {

    // reload page if we are updating background block
    if (typeof CCM_EDIT_MODE !== 'undefined' && CCM_EDIT_MODE) {
        var backgroundBlockHandler = function(e, data) {
            if (typeof data.block === 'object' && data.block.getHandle() === 'background') {
                ConcreteToolbar.disable();
                $.fn.dialog.showLoader();
                window.location.reload();
            }
        }
        Concrete.event.bind('EditModeAddBlockComplete',    backgroundBlockHandler);
        Concrete.event.bind('EditModeUpdateBlockComplete', backgroundBlockHandler);
        Concrete.event.bind('EditModeBlockDeleteComplete', backgroundBlockHandler);
    }

});