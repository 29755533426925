var GenericEventTracking = {

  /**
   * This is a WIP
   * version 1.0.2
   */

  debugMode: false,
  $debugEventIndicator: null,

  eventTypes: {
    bindable: [
      'click',
      'mouseup'
    ],
    triggerable: [
      'mouseup',
      'touchend'
    ],
    preventable: [
      'click'
    ]
  },

  /**
   * debugMode defaults to false
   *
   * @param debugMode boolean
   */
  init: function(debugMode) {
    this.debug(debugMode || false);
    this.log('init');
    this.bindClicks();
  },

  /**
   * Set debug mode, optional parameter defaults to true
   * @param bool
   */
  debug: function(bool) {
    this.debugMode = (bool === undefined) ? true : Boolean(bool);
    if (this.debugMode && ! self.$debugEventIndicator) {
      this.log('add debug view');
      $(function() {
        self.$debugEventIndicator = $('<div/>', {
          id : 'event-debug-indicator',
          style: 'position: fixed; display: block; width: 80px; height: 80px; border-radius: 50% 50% 50% 0; background: #fff; border: 1px solid #ccc; left: -1px; bottom: -1px; z-index: 1000; text-align: center; line-height: 80px; color: #888;',
          html: '0'
        }).appendTo('body');
      });
    }
  },

  triggerEventIndicator: function() {
    clearTimeout(this.debugTimer);
    self.$debugEventIndicator.css('background', 'red').html(
      parseInt(self.$debugEventIndicator.html()) + 1
    );

    this.debugTimer = setTimeout(function() {
      self.$debugEventIndicator.css('background', '#fff');
    }, 500);
  },

  log: function() {
    if (this.debugMode) {
      console.log.apply(null, ['GenericEventTracking -'].concat(Array.from(arguments)));
    }
  },

  getActionByElement: function($el) {
    var type = 'clickLink';
    var href = $el.attr('href') || '';
    if (href.match(/^mailto:/)) {
      type = 'clickEmail';
    } else if (href.match(/^tel:/) || href.match(/^callto:/)) {
      type = 'clickPhoneNumber';
    }
    return type;
  },

  getEventObjectByElement: function($el) {
    var object;
    var mixed = $el.data('event-on-click');
    if (typeof mixed === 'object') {
      object = mixed;
    } else if (typeof mixed === 'string') {
      try {
        mixed = mixed.replace(/'/g, '"');
        object = JSON.parse(mixed);
      } catch (err) {
        object = {};
      }
    } else {
      object = {};
    }

    object.event    = object.event    || 'click';
    object.category = object.category || 'link';
    object.action   = object.action   || this.getActionByElement($el);
    object.href     = object.href     || $el.attr('href');
    object.text     = object.text     || object.label || $el.text().trim();
    object.target   = object.target   || $el.attr('target');

    switch (object.action) {
      case 'clickEmail':
      case 'clickPhoneNumber':
        object.label = $el.attr('href').replace(/\s/g, '').replace(/^[a-z]+\:/, '');
        break;
      default:
        switch (object.category) {
          default:
            object.label = object.label || object.href;
            break;
          case 'link':
          case 'button':
            if (typeof object.href !== 'undefined') {
              if (object.href[0] === '#' || object.href.match(window.location.href + '#')) {
                if (object.href[0] === '#') {
                  object.href = window.location.href + object.href;
                }
                object.label = '#' + object.href.split('#')[1];
              } else {
                object.label = object.label || object.href || object.text;
              }
            }
            break;
        }
        break;
    }

    object.text = object.text || object.label;
    return object;
  },

  shouldTriggerLocationByCallback: function(object) {
    var triggerByCallback = false;
    if (object.useCallback === true || ((object.category === 'link' || object.category === 'button' || object.category === 'file') && object.label[0] !== '#')) {
      triggerByCallback = true;
    }
    return triggerByCallback;
  },

  bindClicks: function() {
    var self = this;
    $(function() {
      self.log('bindings events');

      var $body = $('body');
      var selectors = [

        /**
         *    Track preconfigured elements e.g.
         *
         *    <a href="https://www.google.fi" data-event-on-click>Hakukone</a>
         *    Google Tag Manager Data Layer event
         *    {
                 *        event    : 'click',
                 *        category : 'link',
                 *        action   : 'clickLink',
                 *        label    : 'https://www.google.fi',
                 *        href     : 'https://www.google.fi',
                 *        text     : 'Hakukone'
                 *    }
         *    Google Analytics event
         *    {
                 *        eventCategory : 'link',
                 *        eventAction   : 'clickLink',
                 *        eventValue    : 'https://www.google.fi'
                 *    }
         *
         *    <a href="https://www.facebook.com/lorem/ipsum/dolor" data-event-on-click>Facebook</a>
         *    <a href="https://www.facebook.com/lorem/ipsum/dolor" data-event-on-click='{"text":"Facebook"}'><i class="fa fa-facebook"></i></a>
         *    Google Tag Manager Data Layer event
         *    {
                 *        event    : 'click',
                 *        category : 'link',
                 *        action   : 'clickLink',
                 *        label    : 'https://www.facebook.com/lorem/ipsum/dolor',
                 *        href     : 'https://www.facebook.com/lorem/ipsum/dolor',
                 *        text     : 'Facebook'
                 *    }
         *    Google Analytics event
         *    {
                 *        eventCategory : 'link',
                 *        eventAction   : 'clickLink',
                 *        eventValue    : 'https://www.facebook.com/lorem/ipsum/dolor'
                 *    }
         *
         */
        '[data-event-on-click]',

        /**
         *    Automatically track links with a class starting cta-button e.g.
         *
         *    <a href="https://www.google.fi" class="cta-button">Content link</a>
         *    <a href="https://www.google.fi" class="cta-button-blue">Content link</a>
         *    Google Tag Manager Data Layer event
         *    {
                 *        event    : 'click',
                 *        category : 'link',
                 *        action   : 'clickLink',
                 *        label    : 'https://www.google.fi',
                 *        href     : 'https://www.google.fi',
                 *        text     : 'Content link'
                 *    }
         *    Google Analytics event
         *    {
                 *        eventCategory : 'link',
                 *        eventAction   : 'clickLink',
                 *        eventValue    : 'text=Content link,href=https://www.google.fi'
                 *    }
         *
         */
        'a[class*="cta-button"]:not([href^="#"]):not([data-event-on-click])',

        /**
         *    Automatically track links starting with a hashtag
         *
         *    <a href="#ota-yhteytta">Ota yhteyttä</a>
         *    Google Tag Manager Data Layer event
         *    {
                 *        event    : 'click',
                 *        category : 'link',
                 *        action   : 'clickLink',
                 *        label    : '#ota-yhteytta'
                 *        href     : 'http://www.example.com/#ota-yhteytta',
                 *        text     : 'Ota yhteyttä',
                 *    }
         *    Google Analytics event
         *    {
                 *        eventCategory : 'link',
                 *        eventAction   : 'clickLink',
                 *        eventValue    : 'text=Ota yhteyttä,href=http://www.example.com/#ota-yhteytta'
                 *    }
         */
        'a[href^="#"]:not([data-event-on-click])',
        'a[href^="'+window.location.href+'#"]:not([data-event-on-click])',

        /**
         *    Automatically track phone number links
         *
         *    <a href="tel:+1234567890">Soita</a>
         *    Google Tag Manager Data Layer event
         *    {
                 *        event    : 'click',
                 *        category : 'link',
                 *        action   : 'clickPhoneNumber',
                 *        label    : '+1234567890'
                 *        href     : 'tel:+1234567890',
                 *        text     : 'Soita',
                 *    }
         *    Google Analytics event
         *    {
                 *        eventCategory : 'link',
                 *        eventAction   : 'clickPhoneNumber',
                 *        eventValue    : '+1234567890'
                 *    }
         */
        'a[href^=tel]:not([data-event-on-click])',
        'a[href^=callto]:not([data-event-on-click])',

        /**
         *    Automatically track email links
         *
         *    <a href="mailto:test@example.com">Lähetä sähköpostia</a>
         *    Google Tag Manager Data Layer event
         *    {
                 *        event    : 'click',
                 *        category : 'link',
                 *        action   : 'clickEmail',
                 *        label    : 'test@example.com'
                 *        href     : 'mailto:test@example.com',
                 *        text     : 'Lähetä sähköpostia',
                 *    }
         *    Google Analytics event
         *    {
                 *        eventCategory : 'link',
                 *        eventAction   : 'clickEmail',
                 *        eventValue    : 'test@example.com'
                 *    }
         */
        'a[href^=mailto]:not([data-event-on-click])'

      ];

      // bind events
      $body.on(self.eventTypes.bindable.join(' '), selectors.join(', '), function(e) {
        var callback = null;
        if (self.eventTypes.triggerable.indexOf(e.type) >= 0) {
          self.log('event triggered: '+e.type, this);
          var object = self.getEventObjectByElement($(this));
          if (typeof object === 'object' && self.shouldTriggerLocationByCallback(object)) {
            callback = function() {
              if (self.debugMode) {
                self.log('callback hit, would have set location to: '+object.href);
                self.triggerEventIndicator();
              } else {
                if (object.target !== '_blank') {
                  window.location = object.href;
                }
              }
            };
          } else {
            if (self.debugMode) {
              self.triggerEventIndicator();
            }
          }
          self.send(object, callback);
        }
        if (typeof callback === 'function' || (self.debugMode && self.eventTypes.preventable.indexOf(e.type) >= 0)) {
          e.preventDefault();
        }
      });

    });
  },

  send: function(event, callback) {
    if (typeof event === 'object') {
      if (event) {
        // send tag manager event
        this.log('send DataLayer event', typeof window.dataLayer, event);
        if (typeof callback === 'function') {
          event.eventCallback = callback;
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(event);

        if (typeof ga != 'undefined' && ga) {
          // send analytics event
          var analyticsEvent = this.getAnalyticsFallbackEvent(event);
          if (typeof callback === 'function') {
            analyticsEvent.hitCallback = callback;
            analyticsEvent.hitCallbackFail = callback;
          }
          this.log('send Analytics event', analyticsEvent);
          ga('send', 'event', analyticsEvent);
        }
      } else {
        console.error('Invalid value for event submission. Empty event given.');
      }
    } else {
      console.error('Invalid type for event submission. Expecting an object, ' + typeof event + ' given.');
    }
  },

  /**
   *
   * Converts an event object to old style analytics events
   *
   * @param dataLayerEvent
   * @returns {{eventCategory: (string), eventAction: (string), eventLabel: string}}
   */
  getAnalyticsFallbackEvent: function(dataLayerEvent) {
    return {
      eventCategory : dataLayerEvent.category || 'undefined',
      eventAction   : dataLayerEvent.action   || 'undefined',
      eventLabel    : dataLayerEvent.label    || 'undefined'
    };
  }

};
GenericEventTracking.init();