;$(function() {

    $('form.ajax').on('submit', function(e) {

        var $form         = $(this);
        var $formResponse = $($form).find('.form-response');
        var $submit       = $($form).find('[type="submit"]');
        var identifier    = $form.attr('id');
        var method        = $form.attr('method') || 'post';
        var action        = $form.attr('action');

        if (method && action) {
            e.preventDefault();
            if ( ! $submit.hasClass('loading')) {
                $formResponse.css('opacity', 0);
                $submit.addClass('loading');

                $.ajax({
                    type : method,
                    url  : action,
                    data : $form.serialize()+'&ts='+new Date().getTime(),
                    success: function(res) {
                        var $res = $('<p/>', {
                            html : $('#'+identifier + ' .form-response', res).html()
                        });
                        var hasErrors = $('.ccm-error', $res).length;
                        $formResponse.html($res.html());
                        if ( ! hasErrors) {
                            $($form).find('.slide-up-after-submission').hide();
                            if ($form.data('redirect')) {
                                window.location = $form.data('redirect');
                                $formResponse.html('<span class="redirection-loader"></span>');
                            }
                            if (typeof ga != 'undefined' && ga) {
                                ga('send', 'event', 'form', 'submit', $form.data('survey-name'));
                            }
                            window.dataLayer = window.dataLayer || [];
                            window.dataLayer.push({
                                'event'    : 'submit',
                                'category' : 'lomake',
                                'action' : 'lähetys',
                                'label' : window.location.href
                            });
                        }
                        $formResponse.css('opacity', 1);
                        $submit.removeClass('loading');
                    },
                    error : function() {
                        $formResponse
                            .html('Lomakkeen lähetyksessä tapahtui virhe! Yritä myöhemmin uudelleen.')
                            .css('opacity', 1)
                        ;
                        $submit.removeClass('loading');
                    }
                });
            }
        }

    });

});