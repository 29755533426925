;$(function() {

    // http://kenwheeler.github.io/slick/
    // https://github.com/kenwheeler/slick/

    if ($.fn.slick) {
        $slider = $('.slider');

        // mediataivas - mtProgressBar
        $slider.on('init', function(event, slick) {
            if (slick.slideCount > 1 && slick.options.mtProgressBar === true) {
                slick.$sliderProgressBar = $('<div/>', {
                    'class' : 'slick-progress-bar',
                    'css'   : { 'animation-duration': parseFloat((slick.options.autoplaySpeed) / 1000) + 's' } // use css instead of style to get vendor prefixes
                }).appendTo(slick.$slider);
                slick.sliderProgressBarTimer = setTimeout(function () {
                    slick.$sliderProgressBar.addClass('on-progress')
                }, 1);
            }
        }).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
            if (slick.slideCount > 1  && slick.options.mtProgressBar === true) {
                slick.$sliderProgressBar.removeClass('on-progress');
                slick.sliderProgressBarTimer = setTimeout(function () {
                    slick.$sliderProgressBar.addClass('on-progress');
                }, slick.options.speed);
            }
        });

        /*// mediataivas - mtPauseOnManualChange
        $slider.on('init', function(event, slick) {
            console.log(slick.options);
            if (slick.slideCount > 1  && slick.options.mtPauseOnManualChange === true) {
                slick.$dots.find('li').add(slick.$nextArrow).add(slick.$prevArrow).on('click.slick-pause', function(e) {
                    // pause on dot and arrow clicks
                    slick.slickPause();
                    slick.$slider.addClass('slick-paused');
                    clearTimeout(slick.sliderProgressBarTimer);
                    slick.options.mtProgressBar = false;
                    slick.$sliderProgressBar.removeClass('on-progress');
                });
            }
        });
        $slider.on('swipe', function(event, slick, direction){
            if (slick.slideCount > 1  && slick.options.mtPauseOnManualChange === true) {
                // pause after manual swipe/drag
                slick.slickPause();
                slick.$slider.addClass('slick-paused');
                clearTimeout(slick.sliderProgressBarTimer);
                slick.options.mtProgressBar = false;
                slick.$sliderProgressBar.removeClass('on-progress');
            }
        });*/

        // slick initialization
        $slider.slick({
            mobileFirst    : true,
            pauseOnHover   : false,
            touchThreshold : 20
        });

    }

});